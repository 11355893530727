<template>
  <!-- Desc: budget and bid component for campaign budget change and adgroup bid change tab -->
  <div class="card min-height-card u-spacing-p-l">
    <loader
      v-show="entityInfo.status === 'loading'"
      class="fill--parent"
      :loading="entityInfo.status === 'loading'"
      :color="'#3684bb'"
    />
    <div v-if="!adjustBudget">
      <div class="u-spacing-pb-l">
        <rb-button
          :text="(config && config.adjustButton) || 'Adjust Budget'"
          type="filled"
          :click-fn="onAdjustBudget"
          class="u-min-width-100px"
        />
      </div>
      <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
        {{ (config && config.title) || 'Daily Budget:' }}
      </div>
      <div class="u-position-relative">
        <rb-input
          v-model="budget"
          class="u-display-flex u-width-100px u-flex-align-items-center"
          :disabled="true"
        />
        <div
          class="u-font-size-5 u-color-grey-lighter value-left u-position-absolute"
        >
          {{ currency }}
        </div>
      </div>
    </div>
    <div
      v-if="adjustBudget"
      class="u-display-flex u-flex-direction-column u-height-100"
    >
      <div class="u-width-260px">
        <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
          {{ (config && config.adjustTitle) || 'Adjust Daily Budget:' }}
        </div>
        <div :key="initialBudgetSet">
          <radioSelectionWithInput
            class="u-spacing-pt-s"
            :radio-config="dailyBudgetConfig"
            :default-radio-selected="lifetimeBudgetRadioDefault"
            :default-input-value="initialBudgetSet"
            :error-message="errorMessage"
            :input-title="inputTitle"
            @onUpdate="onUpdatingValues"
          />
        </div>
      </div>
      <div class="u-spacing-pt-xl">
        <footerButtons
          :disable-left-btn="!budgetChange"
          :left-button-type="!budgetChange ? 'filled' : 'hollow'"
          :button-min-width="'u-min-width-100px'"
          @onClickBtnRight="onCancel"
          @onClickBtnLeft="onSave"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import loader from '@/components/basic/loader';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
// TODO -- Make this generic for budget and bids

export default {
  components: {
    footerButtons,
    loader,
    radioSelectionWithInput
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      initialBudgetSet: null,
      inputTitle: '',
      errorMessage: '',
      reqMetadataMap: null,
      radioValue: null,
      isAdgroup: false,
      lifetimeBudgetRadioDefault: 'set_new_budget',
      config: null,
      getState: null,
      requestPayload: null,
      updateMetadata: null,
      adjustBudget: false,
      budget: null,
      tempBudget: null,
      currency: Vue.prototype.$currency,
      tabConfig: null
    };
  },
  computed: {
    dailyBudgetConfig() {
      const type = this?.config?.type || 'budget';
      return [
        {
          title: `Set new ${type}`,
          type: 'input',
          value: 'set_new_budget'
        },
        {
          title: `Increase ${type}`,
          value: 'increase_budget',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: `Decrease ${type}`,
          value: 'decrease_budget',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ];
    },
    budgetChange() {
      const budgetForMaxCheck =
        this.campaignInfo?.data?.budget_type === 'max_impressions'
          ? this.campaignInfo?.data?.budget
          : this.campaignInfo?.data?.target_daily_budget;

      if (
        this.tabConfig?.minMaxRestrictions?.show &&
        this.tempBudget &&
        this.budget !== this.tempBudget &&
        this.transformTempBudget() < this.tabConfig?.minMaxRestrictions?.min
      ) {
        this.errorMessage = `Min bid should be $${this.tabConfig?.minMaxRestrictions?.min}`;
        return false;
      } else if (
        this.tabConfig?.minMaxRestrictions?.show &&
        this.tempBudget &&
        this.budget !== this.tempBudget &&
        budgetForMaxCheck > this.tabConfig?.minMaxRestrictions?.max &&
        this.transformTempBudget() > this.tabConfig?.minMaxRestrictions?.max
      ) {
        this.errorMessage = `Max bid should be $${this.tabConfig?.minMaxRestrictions?.max}`;
        return false;
      } else if (
        this.tabConfig?.minMaxRestrictions?.show &&
        this.tempBudget &&
        this.budget !== this.tempBudget &&
        budgetForMaxCheck < this.tabConfig?.minMaxRestrictions?.max &&
        this.transformTempBudget() >= budgetForMaxCheck
      ) {
        this.errorMessage = `Max bid should be less than ${
          this.campaignInfo?.data?.budget_type === 'max_impressions'
            ? 'lifetime'
            : 'daily'
        } budget of $${budgetForMaxCheck}`;
        return false;
      } else if (
        this.transformTempBudget() &&
        this.budget !== this.transformTempBudget()
      ) {
        this.errorMessage = '';
        return true;
      } else {
        this.errorMessage = '';
        return false;
      }
    },
    entityInfo() {
      return this.$store.getters[this.getState];
    },
    campaignInfo() {
      return this.$store.getters[this.getCampaignState];
    }
  },
  watch: {
    entityInfo: {
      handler(newValue, oldValue) {
        if (newValue?.status === 'loaded') {
          this.setBudget();
        }
      },
      immediate: true
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.getCampaignState = tabConfig?.apiConfigs?.campaignGetter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.requestPayload = tabConfig?.requestPayload;
    this.reqMetadataMap = tabConfig?.reqMetadataMap;
    this.isAdgroup = tabConfig?.isAdgroup || false;
    this.config = tabConfig?.config;
    this.inputTitle = this.tabConfig?.minMaxRestrictions?.show
      ? 'Default CPC Bid'
      : '';
  },
  methods: {
    onCancel() {
      this.setBudget();
    },
    transformTempBudget() {
      const oldVal = this.budget;
      let delta = parseFloat(this.tempBudget);
      if (this.radioValue?.radioValue === 'set_new_budget') {
        return delta;
      }
      if (this.radioValue?.dropdownValue === 'percentage') {
        delta = oldVal * (delta / 100);
      }
      if (this.radioValue?.radioValue === 'increase_budget') {
        return parseFloat((oldVal + delta).toFixed(2));
      } else {
        return parseFloat((oldVal - delta).toFixed(2));
      }
    },
    onSave() {
      const budget = this.transformTempBudget();
      const reqPayload = this.formatRequestPayload(budget);
      // todo update payload for API

      let entityName = null;
      if (this.tabMetadata.component === 'adgroupDefaultBid') {
        entityName = {
          singular: 'Adgroup Bid',
          plural: 'Adgroup Bids',
          noCount: true
        };
      } else {
        entityName = {
          singular: 'Campaign Budget',
          plural: 'Campaign Budget',
          noCount: true
        };
      }
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: [reqPayload],
        entityId: this.entityId,
        entityType: reqPayload.actionPayload.campaignType,
        callAdgroupFetch: this.isAdgroup,
        snackbar: this.$snackbar,
        entityName: entityName,
        routeData: this.$route
      });
      this.setBudget();
    },

    formatRequestPayload(budget) {
      const reqPayload = cloneDeep(this.requestPayload);
      const reqValues = {
        ':oldBudget':
          this.reqMetadataMap[':oldBudget'] &&
          this.entityInfo?.data[this.reqMetadataMap[':oldBudget']],
        ':newBudget': budget,
        ':name': this.entityInfo?.data?.name,
        ':entityId': this.entityId,
        ':campaignId': this[this.reqMetadataMap[':campaignId']],
        ':pageUrl': window.location.href
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(reqPayload, item, reqValues[item]);
      }
      return reqPayload;
    },
    onAdjustBudget() {
      this.tempBudget = this.budget;
      this.adjustBudget = true;
    },
    onUpdatingValues(item) {
      this.tempBudget = item?.inputValue;
      this.radioValue = item;
    },
    setBudget() {
      this.initialBudgetSet =
        this.entityInfo?.data[this.reqMetadataMap[':oldBudget']] || null;
      this.budget =
        this.entityInfo?.data[this.reqMetadataMap[':oldBudget']] || null;
      this.adjustBudget = false;
      this.tempBudget = null;
      this.radioValue = null;
    }
  }
};
</script>
<style scoped>
.min-height-card {
  min-height: 630px;
}
.value-left {
  left: 8px;
  top: 12px;
}
.value-right {
  right: 8px;
  top: 12px;
}
</style>
